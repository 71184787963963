import React, { useState, useEffect, useCallback } from "react";
import axios from "../components/apis/AxiosBackend"; // Import your Axios instance
import { useNavigate } from "react-router-dom";
import "./SeriesPage.css";
import CategoryFilter from "../components/TVShowsCategoriesFilter"; // Import the CategoryFilter component

const base_url = "https://image.tmdb.org/t/p/original/"; // Base URL for TMDB images

const SeriesPage = () => {
  const [series, setSeries] = useState([]); // Store TV shows data
  const [currentPage, setCurrentPage] = useState(1); // Current page state
  const [totalPages, setTotalPages] = useState(1); // Total pages state
  const [loading, setLoading] = useState(true); // Loading state
  const [selectedCategories, setSelectedCategories] = useState([]); // Store selected categories
  const navigate = useNavigate();

  // Function to fetch TV shows for a given page and categories
  const fetchSeriesForPage = useCallback(async (page, categories = []) => {
    setLoading(true);
    try {
      const response = await axios.get(
        `/tvshows?page=${page}&categories=${categories.join(",")}`
      );

      const {
        tv_shows: fetchedSeries = [],
        total_pages: totalFetchedPages = 1,
      } = response.data || {};

      setSeries(fetchedSeries); // Set the series data
      setTotalPages(totalFetchedPages); // Set the total pages
    } catch (error) {
      console.error("Failed to fetch data:", error);
    } finally {
      setLoading(false);
    }
  }, []);

  // Fetch series when the component mounts or when currentPage or selectedCategories changes
  useEffect(() => {
    fetchSeriesForPage(currentPage, selectedCategories);
  }, [currentPage, selectedCategories, fetchSeriesForPage]);

  const handleClick = (seriesId) => {
    navigate(`/series/${seriesId}`); // Navigate to the SeriesDetail page
  };

  const goToNextPage = () => {
    if (currentPage < totalPages) {
      setCurrentPage((prevPage) => prevPage + 1);
    }
  };

  const goToPreviousPage = () => {
    if (currentPage > 1) {
      setCurrentPage((prevPage) => prevPage - 1);
    }
  };

  const handleCategorySelection = (categories) => {
    setSelectedCategories(categories); // Update selected categories
    setCurrentPage(1); // Reset to page 1 when categories change
  };

  const getPageNumbers = () => {
    const pageNumbers = [];

    // Determine the number of pages to show based on screen size
    const maxPagesToShow = window.innerWidth <= 600 ? 1 : 10; // Show 1 page on mobile, 10 on larger screens

    let startPage = Math.max(currentPage - Math.floor(maxPagesToShow / 2), 1);
    let endPage = Math.min(startPage + maxPagesToShow - 1, totalPages);

    if (endPage - startPage + 1 < maxPagesToShow) {
      startPage = Math.max(endPage - maxPagesToShow + 1, 1);
    }

    for (let i = startPage; i <= endPage; i++) {
      pageNumbers.push(i);
    }
    return pageNumbers;
  };

  return (
    <div className="series-page">
      <CategoryFilter onSelectCategory={handleCategorySelection} />{" "}
      {/* Category Filter */}
      {loading ? (
        <p>Loading...</p> // Loading indicator
      ) : (
        <>
          <div className="series-grid">
            {series.length > 0 ? (
              series.map((tvshow) => (
                <div
                  key={tvshow.tmdb_id}
                  className="series-item"
                  onClick={() => handleClick(tvshow.tmdb_id)}
                >
                  {tvshow.poster_path ? (
                    <img
                      src={`${base_url}${tvshow.poster_path}`}
                      alt={tvshow.title}
                      className="series-poster"
                      loading="lazy"
                    />
                  ) : (
                    <div className="series-poster-placeholder">No Image</div>
                  )}
                  <div className="series-info">
                    <p className="series-title">{tvshow.title}</p>
                  </div>
                </div>
              ))
            ) : (
              <p>No TV shows available</p>
            )}
          </div>
          <div className="pagination">
            <button
              onClick={goToPreviousPage}
              disabled={currentPage === 1}
              className="pagination-button"
            >
              Prev
            </button>
            {getPageNumbers().map((number) => (
              <button
                key={number}
                onClick={() => setCurrentPage(number)}
                className={`pagination-button ${
                  number === currentPage ? "active" : ""
                }`}
              >
                {number}
              </button>
            ))}
            <button
              onClick={goToNextPage}
              disabled={currentPage === totalPages}
              className="pagination-button"
            >
              Next
            </button>
          </div>
        </>
      )}
    </div>
  );
};

export default SeriesPage;
