import React, { useState, useEffect, useCallback, useMemo } from "react";
import "./HomePage.css";
import Banner from "./Banner";
import axios from "./apis/AxiosBackend";
import LoadingScreen from "./LoadingScreen";
import Row from "./Row";
import TVShowRow from "./TVShowRow";


const HomePage = () => {
  const [data, setData] = useState({
    movies: {},
    tvShows: {},
  });
  const [loading, setLoading] = useState(true);
  const [fetching, setFetching] = useState(false);
  const [dataOrder, setDataOrder] = useState([]);

  const fetchData = useCallback(async () => {
    if (fetching) return;

    setFetching(true);
    try {
    

      // Check if data exists in session storage
      const cachedData = sessionStorage.getItem("homePageData");
      if (cachedData) {
        const parsedData = JSON.parse(cachedData);
        setData(parsedData.data);
        setDataOrder(parsedData.order);
        setLoading(false); // Data is loaded from cache
        setFetching(false);
        return;
      }

      // Fetch new data
      const response = await axios.get("/main-page");
      const { results } = response.data;

      if (results) {
        const { movies, tvshows } = results;

        // Flatten and format data for movies
        const flatMovies = Object.keys(movies).reduce((acc, category) => {
          const categoryMovies = movies[category].items.map((movie) => ({
            ...movie,
            category,
            type: "movie",
          }));
          return { ...acc, [category]: categoryMovies };
        }, {});

        // Flatten and format data for TV shows
        const flatTVShows = Object.keys(tvshows).reduce((acc, category) => {
          const categoryTVShows = tvshows[category].items.map((tvshow) => ({
            ...tvshow,
            category,
            type: "tv",
          }));
          return { ...acc, [category]: categoryTVShows };
        }, {});

        // Update data
        const newData = {
          movies: flatMovies,
          tvShows: flatTVShows,
        };

        // Update data order
        const order = [
          ...Object.keys(flatMovies).map((category) => ({
            category,
            type: "movie",
          })),
          ...Object.keys(flatTVShows).map((category) => ({
            category,
            type: "tv",
          })),
        ];

        // Save data to session storage
        sessionStorage.setItem(
          "homePageData",
          JSON.stringify({ data: newData, order })
        );

        setData(newData);
        setDataOrder(order);
      }
    } catch (error) {
      console.error("Failed to fetch data:", error);
    } finally {
      setFetching(false);
      setLoading(false);
    }
  }, [fetching]);

  useEffect(() => {
    fetchData();
  }, [fetchData]);

  const memoizedRows = useMemo(() => {
    const seenCategories = new Set();

    return dataOrder
      .filter(({ category }) => {
        if (seenCategories.has(category)) return false;
        seenCategories.add(category);
        return true;
      })
      .map(({ category, type }) => {
        const dataForCategory =
          type === "movie"
            ? data.movies[category] || []
            : data.tvShows[category] || [];

        return type === "tv" ? (
          <TVShowRow
            key={category}
            title={`${category} TV Shows`}
            category={category}
            cachedData={dataForCategory}
            isFetching={fetching}
            fetchData={fetchData}
          />
        ) : (
          <Row
            key={category}
            title={`${category} Movies`}
            category={category}
            cachedData={dataForCategory}
            isFetching={fetching}
            fetchData={fetchData}
          />
        );
      })
      .filter(Boolean); // Ensure only valid rows are returned
  }, [data, dataOrder, fetching, fetchData]);

  return (
    <div className="HomePage">
         
      {loading && <LoadingScreen />}
      <Banner />
      <div className="collections overlap">{memoizedRows}</div>
    </div>
  );
};

export default HomePage;
