import "./App.css";
import HomePage from "./components/HomePage";
import Layout from "./components/Layout";

import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import SearchPage from "./pages/SearchPage";
import MovieDetail from "./components/MovieDetail";
import SeriesDetail from "./components/SeriesDetail";
import SearchResults from "./components/SearchResults";
import MoviesPage from "./pages/MoviesPage22";
import requests from "./components/Requests";
import SeriesPage from "./pages/SeriesPage";

function App() {
  return (
    <div className="App">
      <Router>
        <Routes>
          <Route
            path="/"
            element={
              <Layout>
                <SearchPage />
              </Layout>
            }
          />
          <Route
            path="/home"
            element={
              <Layout>
                <HomePage />
              </Layout>
            }
          />
          <Route
            path="/movie/:id"
            element={
              <Layout>
                <MovieDetail />
              </Layout>
            }
          />
          <Route
            path="/series/:id"
            element={
              <Layout>
                <SeriesDetail />
              </Layout>
            }
          />

          <Route
            path="/search"
            element={
              <Layout>
                <SearchResults />
              </Layout>
            }
          />
          <Route
            path="/movies"
            element={
              <Layout>
                <MoviesPage fetchUrl={requests.fetchPopular} />
              </Layout>
            }
          />
          <Route
            path="/series"
            element={
              <Layout>
                <SeriesPage fetchUrl={requests.fetchPopular} />
              </Layout>
            }
          />

          <Route path="/*" element={<HomePage />} />
        </Routes>
      </Router>
    </div>
  );
}

export default App;
