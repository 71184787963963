import React, { useEffect, useState } from "react";
import axios from "./Axios";
import { useLocation, useNavigate } from "react-router-dom";
import "./SearchResults.css";
import MovieCard from "./MovieCard"; // Import MovieCard
import TVShowCard from "./TVShowCard"; // Import TVShowCard

// Custom hook to get query parameters
const useQuery = () => {
  return new URLSearchParams(useLocation().search);
};

// Custom hook to fetch available IDs (replace with actual fetching method)
const useAvailableIDs = () => {
  const [availableMovies, setAvailableMovies] = useState([]);
  const [availableTVShows, setAvailableTVShows] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchAvailableIDs = async () => {
      try {
        const moviesResponse = await fetch(process.env.REACT_APP_MOVIE_IDS_URL);
        const tvShowsResponse = await fetch(
          process.env.REACT_APP_TV_SHOW_IDS_URL
        );

        if (!moviesResponse.ok || !tvShowsResponse.ok)
          throw new Error("Failed to fetch available IDs");

        const moviesData = await moviesResponse.text();
        const tvShowsData = await tvShowsResponse.text();

        const moviesList = moviesData
          .split("\n")
          .filter((id) => id.trim() !== "");
        const tvShowsList = tvShowsData
          .split("\n")
          .filter((id) => id.trim() !== "");

        setAvailableMovies(moviesList);
        setAvailableTVShows(tvShowsList);
      } catch (error) {
        setError(error.message);
      } finally {
        setLoading(false);
      }
    };

    fetchAvailableIDs();
  }, []);

  return { availableMovies, availableTVShows, loading, error };
};

const SearchResults = () => {
  const [results, setResults] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const { availableMovies, availableTVShows, loading, error } = useAvailableIDs();
  const query = useQuery().get("query");
  const navigate = useNavigate();
  const base_url = "https://image.tmdb.org/t/p/w200"; // Base URL for images

  useEffect(() => {
    const fetchSearchResults = async () => {
      if (query) {
        try {
          const response = await axios.get(`/search/multi`, {
            params: {
              query,
              page: currentPage,
              per_page: 50,
            },
          });

          const allResults = response.data.results;
          setTotalPages(response.data.total_pages);

          // Filter results based on available IDs
          const filteredResults = allResults.filter((result) => {
            if (result.media_type === "movie") {
              return availableMovies.includes(result.id.toString());
            } else if (result.media_type === "tv") {
              return availableTVShows.includes(result.id.toString());
            }
            return false;
          });

          setResults(filteredResults);
        } catch (error) {
          console.error("Error fetching search results:", error);
        }
      }
    };
    fetchSearchResults();
  }, [query, currentPage, availableMovies, availableTVShows]);

  const handleResultClick = (result) => {
    if (result.media_type === "movie") {
      navigate(`/movie/${result.id}`);
    } else if (result.media_type === "tv") {
      navigate(`/series/${result.id}`);
    } else {
      console.error("Unknown media type:", result.media_type);
    }
  };

  const handlePageChange = (direction) => {
    if (direction === "next" && currentPage < totalPages) {
      setCurrentPage((prevPage) => prevPage + 1);
    } else if (direction === "prev" && currentPage > 1) {
      setCurrentPage((prevPage) => prevPage - 1);
    }
  };

  if (loading) return <div>Loading available IDs...</div>;
  if (error) return <div>Error: {error}</div>;

  return (
    <div className="searchResults">
      <h2>Search Results for "{query}"</h2>
      <div className="resultsGrid">
        {results.length > 0 ? (
          results.map((result) => {
            if (result.media_type === "movie") {
              return (
                <MovieCard
                  key={result.id}
                  movie={result}
                  base_url={base_url}
                  onClick={() => handleResultClick(result)}
                />
              );
            } else if (result.media_type === "tv") {
              return (
                <TVShowCard
                  key={result.id}
                  tvShow={result}
                  base_url={base_url}
                  onClick={() => handleResultClick(result)}
                />
              );
            }
            return null; // In case of unknown media type
          })
        ) : (
          <p>No results found.</p>
        )}
      </div>

      <div className="pagination">
        <button
          onClick={() => handlePageChange("prev")}
          disabled={currentPage === 1}
        >
          Previous
        </button>
        <span>
          Page {currentPage} of {totalPages}
        </span>
        <button
          onClick={() => handlePageChange("next")}
          disabled={currentPage === totalPages}
        >
          Next
        </button>
      </div>
    </div>
  );
};

export default SearchResults;
