import React, { useState,useEffect } from "react";
import { useNavigate } from "react-router-dom";
import "./SearchPage.css";
import logo from "../images/logo.png"; // Ensure this path is correct
import stingo from "../images/stingo.png"; // Ensure this path is correct
import HomeStarryBackground from "../components/HomeStarryBackground"; // Import the background component

const SearchPage = () => {
  const [searchQuery, setSearchQuery] = useState("");
  const navigate = useNavigate();

  const handleSearchSubmit = (e) => {
    e.preventDefault();
    if (searchQuery.trim()) {
      navigate(`/search?query=${searchQuery}`);
    }
  };

  const handleViewFullSite = () => {
    navigate("/home");
  };
  useEffect(()=>{
    document.body.style.overflowX='hidden';
    document.documentElement.style.overflowX='hidden';
    return ()=>{
      document.body.style.overflowX='';
      document.documentElement.style.overflowX='';
    }
  },[]);

  return (
    <div className="search-page">
      {/* Render the starry background */}
      <HomeStarryBackground />

      <div className="logo-container">
        <img src={logo} alt="Stingo Logo" className="logo" />
        <img src={stingo} alt="Stingo Logo" className="stingo" />
      </div>
      <form className="search-container" onSubmit={handleSearchSubmit}>
        <input
          type="text"
          placeholder="Enter keywords..."
          className="search-input"
          value={searchQuery}
          onChange={(e) => setSearchQuery(e.target.value)}
        />
        <button type="submit" className="search-button">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="16"
            height="16"
            fill="currentColor"
            className="bi bi-search"
            viewBox="0 0 16 16"
          >
            <path d="M11.742 10.344a6.5 6.5 0 1 0-1.397 1.398h-.001l3.85 3.85a1 1 0 0 0 1.415-1.415l-3.85-3.85zm-5 (1.397a5.5 5.5 0 1 1 7.778-7.778 5.5 5.5 0 0 1-7.778 7.778z" />
          </svg>
        </button>
      </form>
      <button className="view-site-button" onClick={handleViewFullSite}>
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="16"
          height="16"
          fill="currentColor"
          className="bi bi-house"
          viewBox="0 0 16 16"
        >
          <path d="M8 3.293l-6 6V14.5a1.5 1.5 0 0 0 1.5 1.5h3A1.5 1.5 0 0 0 8 14.5V12a1 1 0 0 1 2 0v2.5A1.5 1.5 0 0 0 11.5 16h3a1.5 1.5 0 0 0 1.5-1.5V9.293l-6-6zm1 9.707V14.5a.5.5 0 0 1-.5.5h-3a.5.5 0 0 1-.5-.5v-1.5H4v1.5a1.5 1.5 0 0 0 1.5 1.5h3A1.5 1.5 0 0 0 10 14.5V12a1 1 0 0 1 1 1v2.5zM7 13v1h2v-1H7zm-5-7.5V7h1V4.707l-.646-.646A.5.5 0 0 1 1.5 4H0v1h.707l.647.647L2 6.5z" />
        </svg>
        {/* add the text color black  */}
        <span style={{ marginLeft: "8px", color: "rgba(0, 0, 0, 0.8)" }}>
          HomePage
        </span>
      </button>
    </div>
  );
};

export default SearchPage;
