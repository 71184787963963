import React, { useState } from "react";
import "./CategoryFilter.css";

const CategoryFilter = ({ onSelectCategory }) => {
  // Manually setting the categories in an array
  const categories = [
    "Action",
    "Comedy",
    "Crime",
    "Drama",
    "Thriller",
    "Adventure",
    "Science Fiction",
    "Mystery",
    "Romance",
    "History",
    "Family",
    "Fantasy",
    "Animation",
    "Documentary",
    "Music",
    "Horror",
    "War",
    "Western",
  ];

  const [selectedCategory, setSelectedCategory] = useState(null);
  const [isOpen, setIsOpen] = useState(false); // State for controlling visibility of the filter menu

  const handleCategoryClick = (category) => {
    const newSelectedCategory = selectedCategory === category ? null : category;
    setSelectedCategory(newSelectedCategory);
    onSelectCategory(newSelectedCategory ? [newSelectedCategory] : []); // Pass the updated list to the parent component
  };

  const toggleMenu = () => {
    setIsOpen(!isOpen); // Toggle the visibility of the filter menu
  };

  return (
    <div className="category-filter">
      <button className="filter-toggle" onClick={toggleMenu}>
        {isOpen ? "Hide Filters" : "Show Filters"}
      </button>
      {isOpen && (
        <div className="category-menu">
          <button
            onClick={() => {
              setSelectedCategory(null);
              onSelectCategory([]); // Deselect all categories
            }}
            className={`category-button ${selectedCategory === null ? "selected" : ""}`}
          >
            All
          </button>
          {categories.map((category) => (
            <button
              key={category}
              onClick={() => handleCategoryClick(category)}
              className={`category-button ${selectedCategory === category ? "selected" : ""}`}
            >
              {category}
            </button>
          ))}
        </div>
      )}
    </div>
  );
};

export default CategoryFilter;
