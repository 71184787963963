import React, { useState, useEffect, useCallback } from "react";
import axios from "../components/apis/AxiosBackend";
import { useNavigate } from "react-router-dom";
import "./MoviesPage.css";
import CategoryFilter from "../components/CategoryFilter";

const base_url = "https://image.tmdb.org/t/p/original/";

const MoviesPage = () => {
  const [movies, setMovies] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const [loading, setLoading] = useState(true);
  const [selectedCategories, setSelectedCategories] = useState([]);
  const navigate = useNavigate();

  const fetchMoviesForPage = useCallback(async (page, categories = []) => {
    setLoading(true);

    try {
      const response = await axios.get(
        `/movies?page=${page}&categories=${categories.join(",")}`
      );
      const { movies: fetchedMovies, total_pages: totalFetchedPages } =
        response.data;

      setMovies(fetchedMovies);
      setTotalPages(totalFetchedPages);
      setLoading(false);
    } catch (error) {
      console.error("Failed to fetch movies:", error);
      setLoading(false);
    }
  }, []);

  useEffect(() => {
    // Fetch movies whenever the page or selected categories change
    fetchMoviesForPage(currentPage, selectedCategories);
  }, [currentPage, selectedCategories, fetchMoviesForPage]);

  const handleClick = (movieId) => {
    navigate(`/movie/${movieId}`);
  };

  const goToNextPage = () => {
    if (currentPage < totalPages) {
      setCurrentPage((prevPage) => prevPage + 1);
    }
  };

  const goToPreviousPage = () => {
    if (currentPage > 1) {
      setCurrentPage((prevPage) => prevPage - 1);
    }
  };

  const handleCategorySelection = (categories) => {
    setSelectedCategories(categories);
    setCurrentPage(1); // Reset to page 1 when categories change
  };

  const getPageNumbers = () => {
    const pageNumbers = [];
  
    // Determine the number of pages to show based on screen size
    const maxPagesToShow = window.innerWidth <= 600 ? 1 : 10; // Show 3 pages on mobile, 10 on larger screens
  
    let startPage = Math.max(currentPage - Math.floor(maxPagesToShow / 2), 1);
    let endPage = Math.min(startPage + maxPagesToShow - 1, totalPages);
  
    if (endPage - startPage + 1 < maxPagesToShow) {
      startPage = Math.max(endPage - maxPagesToShow + 1, 1);
    }
  
    for (let i = startPage; i <= endPage; i++) {
      pageNumbers.push(i);
    }
    return pageNumbers;
  };
  

  return (
    <div className="movies-page">
      

      <CategoryFilter onSelectCategory={handleCategorySelection} />

      {loading ? (
        <p>Loading...</p>
      ) : (
        <>
          <div className="movies-grid">
            {movies.length > 0 ? (
              movies.map((movie) => (
                <div
                  key={movie.tmdb_id}
                  className="movie-item"
                  onClick={() => handleClick(movie.tmdb_id)}
                >
                  {movie.poster_path ? (
                    <img
                      src={`${base_url}${movie.poster_path}`}
                      alt={movie.title}
                      className="movie-poster"
                      loading="lazy"
                    />
                  ) : (
                    <div className="movie-poster-placeholder">No Image</div>
                  )}
                  <div className="movie-info">
                    <p className="movie-title">{movie.title}</p>
                  </div>
                </div>
              ))
            ) : (
              <p>No movies available</p>
            )}
          </div>
          <div className="pagination">
            <button
              onClick={goToPreviousPage}
              disabled={currentPage === 1}
              className="pagination-button"
            >
              Prev
            </button>
            {getPageNumbers().map((number) => (
              <button
                key={number}
                onClick={() => setCurrentPage(number)}
                className={`pagination-button ${
                  number === currentPage ? "active" : ""
                }`}
              >
                {number}
              </button>
            ))}
            <button
              onClick={goToNextPage}
              disabled={currentPage === totalPages}
              className="pagination-button"
            >
              Next
            </button>
          </div>
        </>
      )}
    </div>
  );
};

export default MoviesPage;
