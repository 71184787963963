// app/store.js
import { configureStore } from "@reduxjs/toolkit";
import userReducer from "../features/userSlice"; // Adjust the path if necessary

const store = configureStore({
  reducer: {
    user: userReducer,
  },
});

export { store };
