import React, { useState, useEffect, useRef } from "react";
import { useNavigate } from "react-router-dom";
import TVShowCard from "./TVShowCard";
import "./Row.css";

const TVShowRow = ({
  title,
  fetchUrl, // Backend endpoint for fetching TV shows
  isLargeRow = false,
  category, // New prop for category
  fetchData, // Added fetchData prop
  cachedData = [], // Default to empty array
  fetchedData = [], // Added prop for fetched data
}) => {
  const [tvShows, setTvShows] = useState([...cachedData, ...fetchedData]); // Combine cached and fetched data
  const [loading, setLoading] = useState(!fetchedData.length && !cachedData.length); // Only handle initial loading state
  const collectionSliderRef = useRef(null);
  const navigate = useNavigate();
  const [imageRetries, setImageRetries] = useState({}); // Track retry counts

  const base_url = "https://image.tmdb.org/t/p/original/";

  // Throttle function to limit scroll rate
  const throttle = (func, limit) => {
    let lastFunc;
    let lastRan;
    return function () {
      const context = this;
      const args = arguments;
      if (!lastRan) {
        func.apply(context, args);
        lastRan = Date.now();
      } else {
        clearTimeout(lastFunc);
        lastFunc = setTimeout(function () {
          if (Date.now() - lastRan >= limit) {
            func.apply(context, args);
            lastRan = Date.now();
          }
        }, limit - (Date.now() - lastRan));
      }
    };
  };

  const scrollLeft = throttle(() => {
    if (collectionSliderRef.current) {
      const { scrollLeft, clientWidth } = collectionSliderRef.current;
      collectionSliderRef.current.scrollTo({
        left: Math.max(scrollLeft - clientWidth / 2, 0),
        behavior: "smooth",
      });
    }
  }, 200);

  const scrollRight = throttle(() => {
    if (collectionSliderRef.current) {
      const { scrollLeft, clientWidth, scrollWidth } =
        collectionSliderRef.current;
      collectionSliderRef.current.scrollTo({
        left: Math.min(scrollLeft + clientWidth / 2, scrollWidth),
        behavior: "smooth",
      });
    }
  }, 200);

  // De-duplicate TV shows by tmdb_id
  const uniqueTvShows = tvShows.reduce((acc, current) => {
    const exists = acc.find((tvShow) => tvShow.tmdb_id === current.tmdb_id);
    if (!exists) {
      acc.push(current);
    }
    return acc;
  }, []);

  // Handle image error and retry logic
  const handleImageError = (e, tvShowId) => {
    const maxRetries = 3;
    const retryDelay = 1000;

    if (!imageRetries[tvShowId]) {
      setImageRetries((prev) => ({ ...prev, [tvShowId]: 0 }));
    }

    const retries = imageRetries[tvShowId] || 0;

    if (retries < maxRetries) {
      setTimeout(() => {
        e.target.src = `${base_url}${e.target.dataset.src}`;
        setImageRetries((prev) => ({ ...prev, [tvShowId]: retries + 1 }));
      }, retryDelay);
    } else {
      console.log(
        `Failed to load image for TV show ID ${tvShowId} after ${maxRetries} retries.`
      );
    }
  };

  useEffect(() => {
    if (!fetchedData.length) {
      fetchData(fetchUrl, category, "tv");
    } else {
      setTvShows((prevTvShows) => [...new Set([...prevTvShows, ...fetchedData])]);
      setLoading(false);
    }
  }, [fetchedData, fetchData, fetchUrl, category]);
  
  useEffect(() => {
    if (cachedData.length) {
      setTvShows((prevTvShows) => [...new Set([...prevTvShows, ...cachedData])]);
      setLoading(false);
    }
  }, [cachedData]);
  

  return (
    <div className="row">
      <h2 className="row-title">{title}</h2>
      <button
        className="arrow arrow-left"
        onClick={scrollLeft}
        aria-label="Scroll left"
      >
        {"<"}
      </button>
      <button
        className="arrow arrow-right"
        onClick={scrollRight}
        aria-label="Scroll right"
      >
        {">"}
      </button>
      <div className="collection-slider" ref={collectionSliderRef}>
        {loading
          ? Array.from({ length: 10 }).map((_, index) => (
              <div className="row_posterContainer" key={`placeholder-${index}`}>
                <div className="placeholder" />
              </div>
            ))
          : uniqueTvShows.map((tvShow, index) => (
              <TVShowCard
                key={`${tvShow.tmdb_id}-${index}`}
                tvShow={tvShow}
                isLargeRow={isLargeRow}
                base_url={base_url}
                onClick={() => navigate(`/series/${tvShow.tmdb_id}`)}
                onError={(e) => handleImageError(e, tvShow.tmdb_id)}
              />
            ))}
        <button
          className="all-button"
          onClick={() => navigate(`/series?categories=${category}`)}
        >
          View All TV Shows
        </button>
      </div>
    </div>
  );
};

export default TVShowRow;
