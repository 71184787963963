import React, { useState, useRef, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import MovieCard from "./MovieCard";
import "./Row.css";

const Row = ({
  title,
  fetchUrl,
  isLargeRow = false,
  category,
  cachedData = [],
  fetchedData = [],
  fetchData,
}) => {
  const [loading, setLoading] = useState(true);
  const collectionSliderRef = useRef(null);
  const navigate = useNavigate();
  const [imageRetries, setImageRetries] = useState({});

  const base_url = "https://image.tmdb.org/t/p/original/";

  // Throttle function to limit the rate of scroll events
  const throttle = (func, limit) => {
    let lastFunc;
    let lastRan;
    return function () {
      const context = this;
      const args = arguments;
      if (!lastRan) {
        func.apply(context, args);
        lastRan = Date.now();
      } else {
        clearTimeout(lastFunc);
        lastFunc = setTimeout(function () {
          if (Date.now() - lastRan >= limit) {
            func.apply(context, args);
            lastRan = Date.now();
          }
        }, limit - (Date.now() - lastRan));
      }
    };
  };

  const scrollLeft = throttle(() => {
    if (collectionSliderRef.current) {
      const { scrollLeft, clientWidth } = collectionSliderRef.current;
      collectionSliderRef.current.scrollTo({
        left: Math.max(scrollLeft - clientWidth / 2, 0),
        behavior: "smooth",
      });
    }
  }, 200);

  const scrollRight = throttle(() => {
    if (collectionSliderRef.current) {
      const { scrollLeft, clientWidth, scrollWidth } =
        collectionSliderRef.current;
      collectionSliderRef.current.scrollTo({
        left: Math.min(scrollLeft + clientWidth / 2, scrollWidth),
        behavior: "smooth",
      });
    }
  }, 200);

  // De-duplicate movies by tmdb_id
  const uniqueMovies = [...new Map([...cachedData, ...fetchedData].map(movie => [movie.tmdb_id, movie])).values()];

  // Handle image error and retry logic
  const handleImageError = (e, movieId) => {
    const maxRetries = 3;
    const retryDelay = 1000;

    if (!imageRetries[movieId]) {
      setImageRetries(prev => ({ ...prev, [movieId]: 0 }));
    }

    const retries = imageRetries[movieId] || 0;

    if (retries < maxRetries) {
      setTimeout(() => {
        e.target.src = `${base_url}${e.target.dataset.src}`;
        setImageRetries(prev => ({ ...prev, [movieId]: retries + 1 }));
      }, retryDelay);
    } else {
      console.log(`Failed to load image for movie ID ${movieId} after ${maxRetries} retries.`);
    }
  };

  useEffect(() => {
    // Load cached data first
    if (cachedData.length) {
      setLoading(false);
    }

    // Fetch new data if not present or updated
    if (!fetchedData.length) {
      fetchData(fetchUrl, category, "movie");
    } else {
      setLoading(false);
    }
  }, [cachedData, fetchedData, fetchUrl, category, fetchData]);

  return (
    <div className="row">
      <h2 className="row-title">{title}</h2>
      <button
        className="arrow arrow-left"
        onClick={scrollLeft}
        aria-label="Scroll left"
      >
        {"<"}
      </button>
      <button
        className="arrow arrow-right"
        onClick={scrollRight}
        aria-label="Scroll right"
      >
        {">"}
      </button>
      <div className="collection-slider" ref={collectionSliderRef}>
        {loading
          ? Array.from({ length: 10 }).map((_, index) => (
              <div className="row_posterContainer" key={`placeholder-${index}`}>
                <div className="placeholder" />
              </div>
            ))
          : uniqueMovies.map((movie) => (
              <MovieCard
                key={movie.tmdb_id}
                movie={movie}
                isLargeRow={isLargeRow}
                base_url={base_url}
                onClick={() => navigate(`/movie/${movie.tmdb_id}`)}
                onError={(e) => handleImageError(e, movie.tmdb_id)}
              />
            ))}
        <button
          className="all-button"
          onClick={() => navigate(`/movies?categories=${category}`)}
        >
          View All Movies
        </button>
      </div>
    </div>
  );
};

export default Row;
