import React from "react";
import "./MovieCard.css";

const MovieCard = ({ movie, isLargeRow, base_url, onClick, onError }) => {
  const releaseYear = movie.release_date
    ? new Date(movie.release_date).getFullYear()
    : null;
  return (
    <div className="movie-card" onClick={onClick}>
      <div className="movie-card-image-wrapper">
        <img
          className={`movie-card-poster ${isLargeRow ? "large" : ""}`}
          src={`${base_url}${movie.poster_path}`}
          alt={movie.title}
          onError={onError}
        />
        <div className="movie-card-info">
          <div className="movie-card-title">{movie.title}</div>
          {movie.runtime && (
            <div className="movie-card-details">{movie.runtime} min</div>
          )}
          {releaseYear && (
            <div className="movie-card-details">{releaseYear}</div>
          )}
        </div>
      </div>
    </div>
  );
};

export default MovieCard;
