import React from "react";
import "./TVShowCard.css";

const TVShowCard = ({
    tvShow,
  isLargeRow,
  base_url,
  onClick,
  onError,
  showSeasonsEpisodes = true // Flag to show/hide seasons and episodes info

}) => {
  const releaseYear = tvShow.first_air_date
    ? new Date(tvShow.first_air_date).getFullYear()
    : null;

  return (
    <div className="tvshow-card" onClick={onClick}>
      <div className="tvshow-card-image-wrapper">
        <img
          className={`tvshow-card-poster ${isLargeRow ? "large" : ""}`}
          src={`${base_url}${tvShow.poster_path}`}
          alt={tvShow.name}
          onError={onError}
        />
        <div className="tvshow-card-info">
          <div className="tvshow-card-title">{tvShow.title}</div>
          {releaseYear && (
            <div className="tvshow-card-details">{releaseYear}</div>
          )}
          {showSeasonsEpisodes && (
            <div className="tvshow-card-details">
              
              
              <div>Series</div>
             
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default TVShowCard;
