import React, { useRef, useEffect } from 'react';
import moonImageSrc from '../images/moon.png'; // Import the moon image

const HomeStarryBackground = () => {
  const canvasRef = useRef(null);

  useEffect(() => {
    const canvas = canvasRef.current;
    const ctx = canvas.getContext('2d');

    const stars = [];
    const numStars = 200;
    const maxStarSize = 2;

    // Moon properties
    const moon = {
      x: 0, // Initialize x
      y: 0, // Initialize y
      radius: 100, // Increase the moon size
      isHovered: false, // Track hover state directly
      currentShadowBlur: 10, // Start with subtle shadow blur
      targetShadowBlur: 10, // Target shadow blur, changes on hover
      image: new Image(),
    };
    moon.image.src = moonImageSrc; // Set the moon image source

    const lerp = (start, end, t) => {
      return start + (end - start) * t;
    };

    const createStars = () => {
      for (let i = 0; i < numStars; i++) {
        stars.push({
          x: Math.random() * canvas.width,
          y: Math.random() * canvas.height,
          size: Math.random() * maxStarSize,
          speed: Math.random() * 0.5 + 0.1,
          opacity: Math.random() * 0.5 + 0.5,
        });
      }
    };

    const drawMoon = () => {
      // Transition towards the target shadow blur
      moon.currentShadowBlur = lerp(moon.currentShadowBlur, moon.targetShadowBlur, 0.1);

      ctx.save();
      ctx.shadowBlur = moon.currentShadowBlur;
      ctx.shadowColor = 'rgba(255, 255, 255, 1)';

      // Draw the moon image
      ctx.drawImage(
        moon.image,
        moon.x - moon.radius, // Center the image
        moon.y - moon.radius, // Center the image
        moon.radius * 2, // Width of the image
        moon.radius * 2 // Height of the image
      );

      ctx.restore();
    };

    const drawStars = () => {
      ctx.clearRect(0, 0, canvas.width, canvas.height);

      // Draw stars
      stars.forEach((star) => {
        ctx.save();
        ctx.globalAlpha = star.opacity;
        ctx.fillStyle = 'white';
        ctx.beginPath();
        ctx.arc(star.x, star.y, star.size, 0, Math.PI * 2);
        ctx.fill();
        ctx.restore();
      });

      // Draw the moon after stars for layering
      drawMoon();
    };

    const updateStars = () => {
      stars.forEach((star) => {
        star.x -= star.speed;
        if (star.x < 0) star.x = canvas.width;

        // Twinkling effect
        star.opacity += Math.random() * 0.02 - 0.01;
        if (star.opacity < 0.5) star.opacity = 0.5;
        if (star.opacity > 1) star.opacity = 1;
      });
    };

    const animateStars = () => {
      updateStars();
      drawStars();
      requestAnimationFrame(animateStars);
    };

    const resizeCanvas = () => {
      canvas.width = window.innerWidth;
      canvas.height = window.innerHeight;
      stars.length = 0; // Clear the stars array
      createStars();

      // Adjust moon's position dynamically based on canvas size
      moon.x = canvas.width * 0.8; // Adjust to keep the moon in view on different screen sizes
      moon.y = canvas.height * 0.2; // Adjust to keep the moon in view on different screen sizes
    };

    const handleMouseMove = (event) => {
      const { clientX, clientY } = event;

      // Interactive effect on stars
      stars.forEach((star) => {
        const dx = star.x - clientX;
        const dy = star.y - clientY;
        const distance = Math.sqrt(dx * dx + dy * dy);
        if (distance < 100) {
          star.x += dx / 20;
          star.y += dy / 20;
        }
      });

      // Check if the moon is hovered and update the property directly
      const moonDx = clientX - moon.x;
      const moonDy = clientY - moon.y;
      const distanceToMoon = Math.sqrt(moonDx * moonDx + moonDy * moonDy);
      moon.isHovered = distanceToMoon < moon.radius; // Update moon hover state

      // Set target shadow blur based on hover state
      moon.targetShadowBlur = moon.isHovered ? 40 : 10; // Smooth transition target values
    };

    window.addEventListener('resize', resizeCanvas);
    window.addEventListener('mousemove', handleMouseMove);

    resizeCanvas();
    createStars();
    animateStars();

    return () => {
      window.removeEventListener('resize', resizeCanvas);
      window.removeEventListener('mousemove', handleMouseMove);
    };
  }, []);

  return <canvas ref={canvasRef} className="starry-background"></canvas>;
};

export default HomeStarryBackground;
