const API_KEY = process.env.REACT_APP_API_KEY;

const requests = {
  // fetchTrending: `/trending/all/week?language=en-US`,
  // fetchPopular: `/movie?language=en-US&sort_by=popularity.desc`,
  fetchNetflixOriginals: `/discover/tv?api_key=${API_KEY}&with_networks=213`,
  fetchAllMovies: `/main-page-movies-all`,
  // fetchTopRated: `/movie/top_rated?language=en-US`,
  fetchActionMovies: `/main-page-movies?categories=Action`,
  fetchDramaTvshows: `/main-page-tvshows?categories=Drama`,
  fetchComedyMovies: `/main-page-movies?categories=Comedy`,
  fetchHorrorMovies: `/main-page-movies?categories=Horror`,
  fetchRomanceMovies: `/main-page-movies?categories=Romance`,
  fetchDocumentaries: `/main-page-movies?categories=Documentary`,
  fetchSciFiMovies: `/main-page-movies?categories=Science Fiction`,
};

export default requests;
