// LoadingScreen.jsx
import React from 'react';
import './LoadingScreen.css'; // Import the styles for loading screen
import logo from '../images/logo.png'; // Replace with the path to your logo

const LoadingScreen = () => {
  return (
    <div className="loading-screen">
      <img src={logo} alt="Website Logo" className="loading-logo" />
      <p>Loading movies, please wait...</p>
    </div>
  );
};

export default LoadingScreen;
