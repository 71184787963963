import React, { useReducer, useEffect, useCallback, useMemo } from "react";
import axios from "./Axios";
import { useParams, useNavigate } from "react-router-dom";
import "./SeriesDetail.css";

// Define initial state and reducer
const initialState = {
  series: null,
  selectedSeason: 1,
  episodes: [],
  selectedEpisode: null,
  videoSrc: "",
  selectedSource: "Source 1",
  isEpisodesVisible: true,
  loading: true,
  error: null,
};

const reducer = (state, action) => {
  switch (action.type) {
    case 'SET_SERIES':
      return { ...state, series: action.payload, loading: false, error: null };
    case 'SET_EPISODES':
      return { ...state, episodes: action.payload };
    case 'SET_SELECTED_SEASON':
      return { ...state, selectedSeason: action.payload, selectedEpisode: null, videoSrc: "" };
    case 'SET_SELECTED_EPISODE':
      return { ...state, selectedEpisode: action.payload };
    case 'SET_VIDEO_SRC':
      return { ...state, videoSrc: action.payload };
    case 'SET_SELECTED_SOURCE':
      return { ...state, selectedSource: action.payload };
    case 'SET_ERROR':
      return { ...state, error: action.payload, loading: false };
    case 'TOGGLE_EPISODES_VISIBILITY':
      return { ...state, isEpisodesVisible: !state.isEpisodesVisible };
    default:
      return state;
  }
};

const SeriesDetail = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const [state, dispatch] = useReducer(reducer, initialState);

  // Memoize source URLs
  const sourceUrls = useMemo(() => ({
    source1: `https://vidsrc.xyz/embed/tv?tmdb=${id}`,
    source2: `https://multiembed.mov/?video_id=${id}&tmdb=1&s=${state.selectedSeason}&e=${state.selectedEpisode?.episode_number}`,
    source3: `https://www.2embed.cc/embedtv/${id}&s=${state.selectedSeason}&e=${state.selectedEpisode?.episode_number}`
  }), [id, state.selectedSeason, state.selectedEpisode]);

  // Fetch episodes for a specific season with debouncing
  const fetchEpisodes = useCallback(async (seasonNumber) => {
    try {
      const response = await axios.get(`/tv/${id}/season/${seasonNumber}`);
      dispatch({ type: 'SET_EPISODES', payload: response.data.episodes });

      // Set the first episode of the season as default if available
      if (response.data.episodes.length > 0) {
        dispatch({ type: 'SET_SELECTED_EPISODE', payload: response.data.episodes[0] });
      }
    } catch (error) {
      dispatch({ type: 'SET_ERROR', payload: 'Failed to fetch episodes' });
    }
  }, [id]);

  // Fetch series and episodes data
  const fetchSeriesData = useCallback(async () => {
    try {
      dispatch({ type: 'SET_ERROR', payload: null });
      const seriesResponse = await axios.get(`/tv/${id}`);
      dispatch({ type: 'SET_SERIES', payload: seriesResponse.data });
      fetchEpisodes(1); // Fetch episodes for the first season by default
    } catch (error) {
      dispatch({ type: 'SET_ERROR', payload: 'Failed to fetch series details' });
    }
  }, [id, fetchEpisodes]);

  // Load series data on mount
  useEffect(() => {
    fetchSeriesData();
  }, [fetchSeriesData]);

  // Update video source when selected episode changes
  useEffect(() => {
    if (state.selectedEpisode) {
      const videoSrc = `${sourceUrls.source1}&season=${state.selectedSeason}&episode=${state.selectedEpisode.episode_number}`;
      dispatch({ type: 'SET_VIDEO_SRC', payload: videoSrc });
    }
  }, [state.selectedEpisode, sourceUrls.source1, state.selectedSeason]);

  const handleSeasonChange = (event) => {
    const seasonNumber = Number(event.target.value);
    dispatch({ type: 'SET_SELECTED_SEASON', payload: seasonNumber });
    fetchEpisodes(seasonNumber);
  };

  const handleEpisodeClick = (episode) => {
    dispatch({ type: 'SET_SELECTED_EPISODE', payload: episode });
  };

  const handleVideoChange = (index) => {
    let videoSrc;
    if (index === 1) {
      videoSrc = `${sourceUrls.source1}&season=${state.selectedSeason}&episode=${state.selectedEpisode.episode_number}`;
    } else if (index === 2) {
      videoSrc = `${sourceUrls.source2}&season=${state.selectedSeason}&episode=${state.selectedEpisode?.episode_number}`;
    } else if (index === 3) {
      videoSrc = `${sourceUrls.source3}&season=${state.selectedSeason}&episode=${state.selectedEpisode?.episode_number}`;
    }

    dispatch({ type: 'SET_VIDEO_SRC', payload: videoSrc });
    dispatch({ type: 'SET_SELECTED_SOURCE', payload: `Source ${index}` });
  };

  if (state.loading) return <div>Loading...</div>;
  if (state.error) return <div>{state.error}</div>;

  return (
    <div className="seriesDetail">
      <button className="backButton" onClick={() => navigate(-1)}>Back</button>
      <div className="seriesDetailContent">
        {state.selectedEpisode && state.videoSrc && (
          <div className="videoPlayer">
            <div className="videoSourceButtons">
              {[1, 2, 3].map((index) => (
                <button
                  key={index}
                  className={state.selectedSource === `Source ${index}` ? 'selected' : ''}
                  onClick={() => handleVideoChange(index)}
                >
                  Source {index}
                </button>
              ))}
            </div>
            <h2>Now Playing: {state.selectedEpisode.name}</h2>
            <iframe
              id="videoIframe"
              src={state.videoSrc}
              frameBorder="0"
              allowFullScreen
              title={state.selectedEpisode.name}
              loading="lazy" // Lazy loading the iframe
              width="100%"
              height="500px"
            ></iframe>
          </div>
        )}

        <div className="seasonAndButtonWrapper">
          <div className="seasonSelectWrapper">
            <select className="seasonSelect" onChange={handleSeasonChange} value={state.selectedSeason}>
              {state.series && Array.from({ length: state.series.number_of_seasons }, (_, i) => (
                <option key={i + 1} value={i + 1}>Season {i + 1}</option>
              ))}
            </select>
          </div>
          <button className="toggleEpisodesButton" onClick={() => dispatch({ type: 'TOGGLE_EPISODES_VISIBILITY' })}>
            {state.isEpisodesVisible ? "Hide Episodes" : "Show Episodes"}
          </button>
        </div>

        {state.isEpisodesVisible && (
          <div className="episodes">
            <h2>Episodes</h2>
            <table className="episodeTable">
              <thead>
                <tr>
                  <th>Episode</th>
                  <th>Title</th>
                  <th>Action</th>
                </tr>
              </thead>
              <tbody>
                {state.episodes.map((episode) => (
                  <tr key={episode.id} className={state.selectedEpisode?.id === episode.id ? 'selected' : ''}>
                    <td>{episode.episode_number}</td>
                    <td>{episode.name}</td>
                    <td>
                      <button className="episodeButton" onClick={() => handleEpisodeClick(episode)}>
                        {state.selectedEpisode?.id === episode.id ? 'Selected' : 'Select'}
                      </button>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        )}

        {state.series && (
          <div className="seriesInfo">
            <div className="seriesInfoWrapper">
              <img
                className="seriesPoster"
                src={`https://image.tmdb.org/t/p/w300${state.series.poster_path}`}
                alt={state.series.name}
              />
              <div className="seriesDetails">
                <p>{state.series.overview}</p>
              </div>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default SeriesDetail;
