import React, { useState, useEffect,  useCallback } from "react";
import axios from "./Axios";
import { useParams, useNavigate } from "react-router-dom";
import "./MovieDetail.css";

const MovieDetail = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const [movie, setMovie] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [videoSrc, setVideoSrc] = useState(`https://vidsrc.me/embed/${id}`);
  const [selectedSource, setSelectedSource] = useState(""); // State for selected video source

  // Fetch movie data
  useEffect(() => {
    const fetchMovie = async () => {
      try {
        setLoading(true);
        const response = await axios.get(`/movie/${id}`);
        setMovie(response.data);
      } catch (error) {
        console.error("Failed to fetch movie details:", error);
        setError("Failed to load movie details. Please try again later.");
      } finally {
        setLoading(false);
      }
    };

    fetchMovie();
  }, [id]);

  // Restore the selected source from localStorage if available
  useEffect(() => {
    const savedSource = localStorage.getItem(`selectedMovieSource-${id}`);
    if (savedSource) {
      setSelectedSource(savedSource);
      setVideoSrc(savedSource);
    }
  }, [id]);

  // Memoize the video source change handler to prevent unnecessary re-renders
  const handleVideoChange = useCallback(
    (source) => {
      setVideoSrc(source);
      setSelectedSource(source);
      localStorage.setItem(`selectedMovieSource-${id}`, source);
    },
    [id]
  );

  const handleBackClick = () => {
    navigate(-1);
  };

  if (loading) return <div className="loading">Loading...</div>;
  if (error) return <div className="error">{error}</div>;
  if (!movie) return <div>No movie data available</div>;

  return (
    <div className="movieDetailContainer">
      <button className="backButton" onClick={handleBackClick}>
        Back
      </button>
      <div className="movieDetail">
        <div className="videoSourceButtons">
          <button
            className={selectedSource.includes("vidsrc.me") ? "selected" : ""}
            onClick={() => handleVideoChange(`https://vidsrc.me/embed/${id}`)}
          >
            Premium
          </button>
          <button
            className={
              selectedSource.includes("multiembed.mov") ? "selected" : ""
            }
            onClick={() =>
              handleVideoChange(`https://multiembed.mov/?video_id=${id}&tmdb=1`)
            }
          >
            Standard
          </button>
          <button
            className={selectedSource.includes("2embed.cc") ? "selected" : ""}
            onClick={() =>
              handleVideoChange(`https://www.2embed.cc/embed/${id}`)
            }
          >
            Standard 2
          </button>
        </div>
        <div className="videoPlayer">
          <iframe
            src={videoSrc}
            frameBorder="0"
            allowFullScreen
            title={movie.title}
            width="100%"
            height="500px"
            loading="lazy" // Lazy load the iframe
          ></iframe>
        </div>
        <div className="movieDetailContent">
          <img
            className="moviePoster"
            src={`https://image.tmdb.org/t/p/original/${movie.poster_path}`}
            alt={movie.title}
          />
          <div className="movieInfo">
            <p className="movieOverview">{movie.overview}</p>
            <p className="movieDetails">
              <strong>Release Date:</strong> {movie.release_date}
            </p>
            <p className="movieDetails">
              <strong>Rating:</strong> {movie.vote_average.toFixed(1)}
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default MovieDetail;
